<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2023-01-02 16:31:50
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 10:57:55
-->
<template>
  <div class="group" v-if="item.articles.length > 0" :key="item.id">
    <div class="group-title text-ellipsis" @click="handleToggle()">
      <span class="el-icon el-icon-caret-right" :class="{ expanded }"> </span>
      <span>{{ item.usageGroupName }}</span>
    </div>
    <el-collapse-transition>
      <div class="sub-art-list" v-show="expanded">
        <div
          class="art-item content text-ellipsis"
          v-for="j in item.articles"
          :key="j.articleId"
          :class="{ activeLink: j.articleId == activeLink && usageGroupId > 0 }"
          @click="handleActiveItem(j, item.usageGroupId)"
        >
          {{ j.articleName || $t("Detail.noName") }}
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script>
export default {
  name: "ArtCollapseItem",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    activeLink: String,
    usageGroupId: {},
  },
  data() {
    return {
      expanded: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleToggle() {
      this.expanded = !this.expanded;
    },
    handleActiveItem(j, usageGroupId) {
      this.$emit("changeArt", { item: j, usageGroupId });
    },
  },
};
</script>
<style scoped lang="scss">
.group-title {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  padding-left: 3px;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  .el-icon {
    color: rgba(0, 0, 0, 0.2);
    font-size: 14px;
    margin-right: 10px;
    transition: transform 0.3s ease-in-out;
  }
  .expanded {
    transform: rotate(90deg);
  }
}

.sub-art-list {
  padding-left: 24px;
}

.content {
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  width: 240px;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0;
  font-weight: 400;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  &.activeLink {
    color: var(--theme-color);
  }
}

.art-line {
  width: 1px;
  height: calc(100vh);
  background-color: #ddd;
  position: absolute;
  right: 0;
  top: 0;
  .collapse-switch {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    right: -13px;
    top: 30px;
    border: 1px solid #ddd;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    .arrow-icon {
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;
    }
    .expand {
      position: absolute;
      transform: rotate(0deg);
    }
    &.close {
      width: 30px;
      right: -25px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}
</style>
