<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-21 17:38:23
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-03 10:54:39
-->
<template>
  <div class="author">
    <img class="avatar" :src="authorInfo.updateMemberHeadUrl" alt srcset />

    <div class="auth-info">
      <div class="author-name">{{ $t("GroupList.Author.RecentUpdate") }}{{ authorInfo.updateMemberName }}</div>
      <div class="author-datetime">{{ fomateDateTime }}</div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "Author",
  data() {
    return {};
  },
  props: {
    authorInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fomateDateTime() {
      return dayjs(this.authorInfo.updateTime).format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
<style scoped lang="scss">
.author {
  margin-top: 16px;
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .auth-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .author-name {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 24px;
      font-weight: 400;
    }
    .author-datetime {
      font-size: 13px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
    }
  }
}
</style>
