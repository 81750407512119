<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-23 11:01:12
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 10:57:39
-->
<template>
  <div class="art-collapse">
    <div class="art-content" :class="{ isClose: !expand }">
      <div class="art-content-container">
        <div class="art-title">
          <svg-icon class="title-icon" iconClass="detail-collapse"></svg-icon>
          <span>{{ $t("Detail.Catalogue.artCatalogue") }}</span>
        </div>
        <div class="no-group">
          <div
            class="art-item content text-ellipsis"
            :class="{ activeLink: k.articleId == activeLink && (usageGroupId == -1 || !usageGroupId) }"
            v-for="k in defaultGroup.articles"
            :key="'k' + k.id"
            @click="handleActiveItem({ item: k, usageGroupId: defaultGroup.usageGroupId })"
          >
            {{ k.articleName || $t("Detail.noName") }}
          </div>
        </div>
        <div class="has-group">
          <ArtCollapseItem
            :item="i"
            :key="index"
            v-for="(i, index) in customGroup"
            :usageGroupId="usageGroupId"
            @changeArt="handleActiveItem"
            :activeLink="activeLink"
          ></ArtCollapseItem>
        </div>
      </div>
    </div>
    <div class="art-line">
      <!-- 折叠开关 -->
      <span class="collapse-switch" :class="{ close: !expand }" @click="expand = !expand">
        <svg-icon class="arrow-icon" :class="{ expand }" icon-class="left-indent"></svg-icon>
      </span>
    </div>
  </div>
</template>
<script>
import ArtCollapseItem from "./modulecope/ArtCollapseItem.vue";
export default {
  name: "ArtCollapse",
  components: { ArtCollapseItem },
  props: {
    listData: {},
    customGroup: {},
    defaultGroup: {},
  },
  data() {
    return {
      expand: true,
      activeLink: "",
      activeCollapse: "",
      usageGroupId: -1,
    };
  },
  watch: {
    expand(value) {
      this.$emit("expandChange", value);
    },
  },
  mounted() {
    this.activeLink = this.$route.query.articleId;
    this.usageGroupId = this.$route.query.usageGroupId;

    this.getarticleIdInGroup(this.activeLink);
  },
  methods: {
    getarticleIdInGroup(articleId) {
      for (var index in this.customGroup) {
        if (this.customGroup[index].articles[0].articleId == articleId) {
          this.activeCollapse = "g" + parseInt(index);
          break;
        }
      }
    },
    handleToggle(index) {
      if (index == this.activeCollapse) {
        this.activeCollapse = "";
      } else {
        this.activeCollapse = index;
      }
    },
    handleActiveItem({ item, usageGroupId }) {
      this.activeLink = item.articleId;
      this.usageGroupId = usageGroupId;
      this.$emit("artChange", item, usageGroupId);
    },
  },
};
</script>
<style scoped lang="scss">
.art-collapse {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  box-sizing: border-box;
  margin-top: 72px;
  font-size: 14px;
  background-color: #fff;

  .art-content {
    width: 300px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    &.isClose {
      width: 0px !important;
    }
    .art-content-container {
      padding: 24px 20px;
    }
  }
  .art-title {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0;
    font-weight: 400;
    height: 38px;
    line-height: 38px;
    display: flex;
    align-items: center;
    .title-icon {
      width: 1.2em;
      height: 1.2em;
      margin-right: 5px;
    }
  }

  .no-group {
    padding-left: 25px;
  }

  .content {
    cursor: pointer;
    height: 38px;
    line-height: 38px;
    width: 240px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0;
    font-weight: 400;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
    &.activeLink {
      color: var(--theme-color);
    }
  }

  .group-title {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
    height: 38px;
    line-height: 38px;
    cursor: pointer;
    padding-left: 3px;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
    .el-icon {
      color: rgba(0, 0, 0, 0.2);
      font-size: 14px;
      margin-right: 10px;
      transition: transform 0.3s ease-in-out;
    }
    .expanded {
      transform: rotate(90deg);
    }
  }
  .sub-art-list {
    padding-left: 24px;
  }

  .art-line {
    width: 1px;
    height: calc(100vh);
    background-color: #ddd;
    position: absolute;
    right: 0;
    top: 0;
    .collapse-switch {
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 24px;
      height: 24px;
      right: -13px;
      top: 30px;
      border: 1px solid #ddd;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
      .arrow-icon {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;
      }
      .expand {
        position: absolute;
        transform: rotate(0deg);
      }
      &.close {
        width: 30px;
        right: -25px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }
}
</style>
