<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:36:27
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-02-03 12:10:28
-->
<template>
  <div>
    <div class="about" v-if="hasArtContent && artDetail.publishStatus == 1">
      <div class="detail" v-loading="loading">
        <div class="content" :class="{ catalogueopen }" :style="{ paddingRight }">
          <div class="artContent">
            <div class="core">
              <Breadcrumb :data="Breadcrumb" />
            </div>
            <div id="art-title" class="title" v-show="!loading">{{ artDetail.articleName || $t("Detail.noName") }}</div>
            <div id="art-remark" class="remark">{{ artDetail.articleDescribe }}</div>
            <Author class="author" :authorInfo="authorInfo"></Author>
            <Editor class="editor" v-model="html" :defaultConfig="editorConfig" @onChange="onChange"></Editor>
          </div>
        </div>
      </div>
      <!-- 文章列表 -->
      <div class="catalogue" v-if="usageStyleId == 2">
        <ArtCollapse
          :listData="listData"
          :defaultGroup="defaultGroup"
          :customGroup="customGroup"
          :usageCategoryId="artInfo.usageCategoryId"
          @artChange="artChange"
          @expandChange="expandChange"
        ></ArtCollapse>
      </div>
      <!-- 内容目录 -->
      <div class="navigation">
        <div class="navigation-fixed">
          <div class="navigation-title">{{ $t("Detail.NavigationTitle") }}</div>
          <el-tree
            class="navigation-tree"
            ref="treeDataRef"
            node-key="id"
            :empty-text="$t('Detail.noData')"
            :data="treeResult"
            :props="defaultProps"
            :highlight-current="true"
            current-node-key="w-e-element-4328"
            :defaultExpandAll="true"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
    </div>
    <Error v-else class="error" :remark="$t('error.EmptyTip')" />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { Editor } from "@wangeditor/editor-for-vue";
import { SlateNode } from "@wangeditor/editor";
import Author from "./module/Author.vue";
import ArtCollapse from "./module/ArtCollapse.vue";
import Error from "./Error.vue";
export default {
  name: "detail",
  components: { Editor, ArtCollapse, Breadcrumb, Author, Error },
  mounted() {
    this.initData();
  },
  computed: {
    winWidth: function () {
      return this.$store.state.winWidth;
    },
    language: function () {
      return this.$store.state.defaultlanguage;
    },
    usageStyleId: function () {
      return this.$store.state.globalConfig.usageStyleId;
    },
    usageManageId() {
      return this.$store.state.defaultlanDetail.usageManageId;
    },
  },
  watch: {
    language() {
      this.initData();
    },
    $route() {
      this.initData();
    },
  },
  data() {
    return {
      hasArtContent: true,
      loading: false,
      paddingRight: "300px",
      expand: true,
      catalogueopen: true,
      html: "<p><br></p>",
      treeResult: [],
      activeTocId: "",
      scrolling: false,
      defaultProps: { children: "children", label: "text" },
      listData: {},
      artInfo: {},
      artDetail: {
        publishStatus: 1,
      },
      defaultGroup: [],
      customGroup: [],
      authorInfo: {},
      articleName: "",
      editorConfig: { readOnly: true },
      Breadcrumb: [],
    };
  },

  methods: {
    // 初始化数据
    initData() {
      this.hasArtContent = true;
      let { articleId, usageCategoryId, usageGroupId } = this.$route.query;
      let wikiArticleNavParams = {
        articleId,
        usageManageId: this.usageManageId,
        language: this.language,
        usageCategoryId,
        usageGroupId,
      };
      let usageDetailparams = {
        usageCategoryId,
        categoryLanguage: this.language,
      };
      this.loading = true;
      Promise.all([this.$api.wikiArticleNav(wikiArticleNavParams), this.$api.getUsageDetail(usageDetailparams)])
        .then((result) => {
          if (result[0].code == 200 && result[1].code == 200) {
            this.getArticleDetail(result[0]);
            this.getUsageDetail(result[1]);
          } else {
            this.hasArtContent = false;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    wikiArticleNav(res) {
      if (res.data.groupName == null) {
        this.Breadcrumb = [
          {
            name: res.data.categoryName,
            Name: "Collections",
            params: this.$router.params,
            query: this.$route.query.usageCategoryId,
          },
          {
            name: this.articleName,
          },
        ];
      } else {
        this.Breadcrumb = [
          {
            name: res.data.categoryName,
            Name: "Collections",
            params: this.$router.params,
            query: this.$route.query.usageCategoryId,
          },
          {
            name: res.data.groupName,
            Name: "Collections",
            params: this.$router.params,
            query: this.$route.query.usageCategoryId,
          },
          {
            name: this.articleName,
          },
        ];
      }
    },
    expandChange(expand) {
      this.catalogueopen = expand;
    },
    getUsageDetail(res) {
      this.listData = res.data;
      this.customGroup = res.data.customGroup;
      this.defaultGroup = res.data.defaultGroup;
    },
    artChange(data, usageGroupId) {
      this.$router.push({
        name: "Detail",
        params: this.$route.params,
        query: {
          articleId: data.articleId,
          usageGroupId,
          usageCategoryId: this.$route.query.usageCategoryId,
        },
      });
    },
    getArticleDetail(navDetail) {
      let articleId = this.$route.query.articleId;
      this.loading = true;
      this.$api
        .getArticleDetail({
          articleId,
          language: this.language,
          type: "publish",
        })
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.artDetail = res.data;
            this.articleName = res.data.articleName;
            this.authorInfo = {
              updateMemberHeadUrl: res.data.updateMemberHeadUrl,
              updateMemberName: res.data.updateMemberName,
              updateTime: res.data.updateTime,
            };
            this.html = res.data.articleContent || "<p><br></p>";
            this.wikiArticleNav(navDetail);
            //  三项内容为空则显示 缺省页面
            if (!this.articleName && !this.artDetail.articleContent && !this.artDetail.articleDescribe) this.hasArtContent = false;
          } else {
            this.artDetail.publishStatus = 0;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleNodeClick(item) {
      let anchorElement = document.getElementById(item.id);
      let titleHeight = document.getElementById("art-title").offsetHeight;
      let remarkHeight = document.getElementById("art-remark").offsetHeight;
      clearTimeout(this.scrolling);
      this.scrolling = setTimeout(() => {
        this.scrolling = null;
      }, 1500);
      window.scrollTo({
        left: 0,
        top: anchorElement.offsetTop + remarkHeight + titleHeight + 72 + 30, // 可以根据滚动情况增减数值
        behavior: "smooth",
      });
    },
    createPid(headerList) {
      if (headerList.length > 0) {
        headerList.reduce((pre, current, index) => {
          // 首次循环 需要覆值
          if (index == 1) pre.pid = null;
          // 修改
          if (pre.type < current.type) {
            current.pid = pre.id;
          } else if (pre.type == current.type) {
            current.pid = pre.pid;
          } else {
            current.pid = null;
          }
          return current;
        });
      } else {
        return [];
      }
    },
    getChildren(data, result, pid) {
      for (const item of data) {
        if (item.pid === pid) {
          const newItem = { ...item, children: [] };
          result.push(newItem);
          this.getChildren(data, newItem.children, item.id);
        }
      }
    },
    visibleChnage(entries) {
      let observe = entries[0];
      const id = observe.target.getAttribute("id");
      if (observe.isIntersecting && !this.scrolling) {
        this.activeTocId = id;
        this.$refs.treeDataRef.setCurrentKey(id);
        return;
      }
    },
    onChange(editor) {
      this.treeResult = [];
      const headers = editor.getElemsByTypePrefix("header");
      if (headers.length > 0) {
        const observer = new IntersectionObserver(this.visibleChnage, {
          rootMargin: "-90px 0px 0px 0px",
        });
        const headerList = headers.map((header) => {
          return {
            id: header.id,
            type: header.type,
            text: SlateNode.string(header),
          };
        });
        Array.from(headerList, (item) => {
          const element = document.getElementById(item.id);
          observer.observe(element);
        });
        this.createPid(headerList);
        this.getChildren(headerList, this.treeResult, null);
      }
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .core {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .breadcrumb {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.4);
      letter-spacing: -0.2px;
      font-weight: 400;
    }
    .last {
      color: rgba(0, 0, 0, 0.8);
    }
    span {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin: 0 4px;
    }
  }
  .detail {
    width: 100%;
    min-height: calc(100vh - 200px);
    padding-top: 30px;

    .content {
      margin: 0 auto;
      font-size: 17px;
      justify-content: space-between;
      transition: all 0.3s ease-in-out;
      max-width: 800px;

      &.catalogueopen {
        padding-left: 300px;
      }
      &.navopen {
        padding-right: 300px;
      }

      .artContent {
        flex: 1;
        max-width: 1200px;
        margin: 0 30px;
        .title {
          font-size: 36px;
          color: #000000;
          letter-spacing: 0;
          line-height: 1.3;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .remark {
          font-size: 17px;
          color: rgba(0, 0, 0, 0.4);
          letter-spacing: 0;
          line-height: 1.3;
          font-weight: 400;
        }
        .author {
          margin-top: 30px;
          margin-bottom: 10px;
        }
        .editor {
          margin-left: -8px;
          margin-right: -8px;
        }
      }
    }
  }

  .navigation {
    width: 300px;
    background-color: #fff;

    .navigation-fixed {
      position: fixed;
      top: 110px;
      right: 0;
    }

    .navigation-title {
      font-size: 19px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 5px;
    }
    .navigation-tree {
      width: 280px;
      height: calc(100vh - 158px);
      overflow: auto;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      ::v-deep {
        .el-tree-node__label {
          width: 230px;
          overflow: hidden;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .el-tree-node__content {
          border-left: 2px solid #ddd;
          padding: 6px 0;
        }
        .is-current > .el-tree-node__content {
          border-left: 2px solid var(--theme-color);
        }
      }
    }
  }
}
.error {
  height: calc(100vh - 162px);
}
</style>
